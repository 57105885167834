@use "../settings" as *;

.page {
	background: none;
	box-shadow: none;
}

.post-header {
	display: none;
}

.section-container {
	display: flex;
	flex-flow: row wrap;
}

.section-404-content {
	flex: 1;
	min-width: 400px;

	@include media-smaller-than(450px) {
		min-width: auto;
	}
}

.heading-404 {
	font-size: 3em;
	color: var(--text-color);
	font-weight: bold;
	text-transform: uppercase;
	text-shadow: 4px 3px 0 lighten(#98a1f0, 10%);
	line-height: 1.5;
	padding-bottom: $spacing-unit;

	@include dark-mode {
		text-shadow: 2px 1px 0 darken(#98a1f0, 20%);
	}
}

.section-404-image {
	padding-right: $spacing-unit;

	@include center-things(column);

	@include media-smaller-than($spacing-unit * 2 + 400px + 300px + 50px) {
		width: 100%;
		padding: 0;
		padding-bottom: $spacing-unit;
		@include center-things();
	}

	svg {
		width: 300px;
	}

	@include dark-mode {
		.blurple {
			fill: var(--link-color);
		}
	}
}

.suggested-link {
	font-family: "Consolas", monospace;
}

.suggested-similarity {
	opacity: 0.7;
}
